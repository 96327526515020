import React, { FC, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import BusinessSelect from "../../../components/form/BusinessSelect";
import BusinessEmployeeSelect from "../../../components/form/BusinessEmployeeSelect";

interface Props {
    onConfirm: (options: Options) => void
    onHide: () => void
}
interface Options {
    from: string
    to: string
    business_id: string | number
    employee_id: string | number
}
const ReportFilters: FC<Props> = ({ onConfirm, onHide }) => {
    const [options, setOptions] = useState<Options>({
        'from': '',
        'to': '',
        'business_id': "",
        'employee_id': ""
    });

    return (
        <Modal
            size="sm"
            show={true}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-sm"
            className="modal-default"
        >
            <Modal.Header closeButton closeVariant="white">
                <Modal.Title id="example-modal-sizes-title-sm">
                    Relatório
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label htmlFor="exampleInputEmail1" className="form-label">Cliente:</label>
                <BusinessSelect
                    onChange={(business_id) => {
                        setOptions({ ...options, business_id: (business_id !== undefined) ? business_id : "" });
                    }}
                />
                {options.business_id !== '' && (
                    <>
                        <label htmlFor="exampleInputEmail1" className="form-label">Solicitante:</label>
                        <BusinessEmployeeSelect
                            key={options.employee_id}
                            businessId={options.business_id}
                            employeeId={options.employee_id}
                            onChange={(employee_id) => {
                                setOptions({ ...options, employee_id: (employee_id !== undefined) ? employee_id : "" });
                            }}
                        />
                        <br />
                    </>
                )}

                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3">
                            <Form.Label>De:</Form.Label>
                            <Form.Control
                                type="date"
                                onChange={(e) => setOptions({ ...options, from: e.target.value })}
                                value={options.from}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3">
                            <Form.Label>Até:</Form.Label>
                            <Form.Control
                                type="date"
                                onChange={(e) => setOptions({ ...options, to: e.target.value })}
                                value={options.to}
                            />
                        </Form.Group>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-light" onClick={onHide} className="btn-custom btn-custom-light">
                    Cancelar
                </Button>
                <Button variant="primary" onClick={(e) => {
                    if(options.business_id === "") {
                        alert("Selecione o cliente.");
                        return;
                    }

                    if(options.from === "") {
                        alert("Selecione a data de inicio.");
                        return;
                    }

                    if(options.to === "") {
                        alert("Selecione a data de fim.");
                        return;
                    }

                    onConfirm(options);
                }} className="btn-custom bg-custom-gradient">
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ReportFilters;