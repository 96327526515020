import React, {useContext, useRef, useState} from "react";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownItem from "react-bootstrap/DropdownItem";
import useOutsideClick from "../../hooks/useOutsideClick";
import {Link,useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import {AuthContext} from "../../providers/AuthContextProvider";
import LogoHeader from "../../assets/logo.png";
import {useAPI} from "../../hooks/useAPI";

const Header = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const ref = useRef<HTMLAnchorElement>(null);
    const {deleteToken} = useAuth();
    const {doLogout} = useAPI();
    const {setIsAuth, user} = useContext(AuthContext);    
    const navigate = useNavigate();

    useOutsideClick(ref, () => {
        setShowDropdown(false);
    });

    return (
        <header>
            <div className="container-fluid p-0">
                <div className="d-grid align-items-center justify-content-center justify-content-lg-start header-content">
                    <Link to="/admin/entries" className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
                        <img className="bi me-2" width="132" role="img" aria-label="Bootstrap" src={LogoHeader} />
                    </Link>
                    <div className="user-name text-right">Olá, {user?.name}</div>
                    <div className="dropdown text-end">
                        <a href="#" className="d-block link-dark text-decoration-none dropdown-toggle"
                          id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false"
                           onClick={(e) => setShowDropdown(!showDropdown)}
                           ref={ref}
                        >
                            <img src="/media/avatars/blank.png" alt="mdo" width="48" height="48" style={{"border": "2px solid #fff"}}
                                 className="rounded-circle" />
                        </a>
                        <DropdownMenu show={showDropdown} align={"end"}>
                            <DropdownItem><a className="dropdown-item" href="" onClick={(e) => {
                                e.preventDefault();
                                doLogout();
                                deleteToken();
                                setIsAuth(false);
                                navigate("/auth/login");
                            }}>Sair</a></DropdownItem>
                        </DropdownMenu>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;