import React from "react";
import {Route, Routes} from "react-router-dom";
import UserPage from "../modules/user/UserPage";
import BusinessPage from "../modules/business/BusinessPage";
import BusinessEditPage from "../modules/business/BusinessEditPage";
import BusinessAddPage from "../modules/business/BusinessAddPage";
import ErrorBoundary from "../components/ErrorBoundary";
import EntryPage from "../modules/entry/EntryPage";
import ServicesPage from "../modules/entry/ServicesPage";
import EntryViewPage from "../modules/entry/EntryViewPage";
import TeamPage from "../modules/team/TeamPage";
import TeamAddPage from "../modules/team/TeamAddPage";
import TeamEditPage from "../modules/team/TeamEditPage";
import ServiceTypesPage from "../modules/serviceTypes/ServiceTypesPage";

const PrivateRoutes = () => {

    return (
        <>
            <ErrorBoundary>
                <Routes>
                    <Route path={'/'} element={<EntryPage />} />
                    <Route path={'/admin/entries'} element={<EntryPage />} />
                    <Route path={'/admin/entries/:id/view'} element={<EntryViewPage />} />
                    <Route path={'/admin/services'} element={<ServicesPage />} />
                    <Route path={'/admin/users'} element={<UserPage />} />
                    <Route path={'/admin/service-types'} element={<ServiceTypesPage />} />
                    <Route path={'/admin/teams'} element={<TeamPage />} />
                    <Route path={'/admin/teams/add'} element={<TeamAddPage />} />     
                    <Route path={'/admin/teams/:id/edit'} element={<TeamEditPage />} />
                    <Route path={'/admin/businesses'} element={<BusinessPage />} />
                    <Route path={'/admin/businesses/:id/edit'} element={<BusinessEditPage />} />
                    <Route path={'/admin/businesses/add'} element={<BusinessAddPage />} />                    
                </Routes>
            </ErrorBoundary>
        </>
    );
}

export default PrivateRoutes;