import React, {FC} from "react";
import ClipLoader from "react-spinners/ClipLoader";

interface Props {
    fullscreen?: boolean
}

const Loader: FC<Props> = ({fullscreen=false}) => {
    return (
        <>
            {!fullscreen ? (
                    <div style={{
                        "fontSize": "1rem",
                        "position": "fixed",
                        "top": "45%",
                        "width": "100%"
                    }} className={"text-center"}>
                        <ClipLoader size={"1rem"} /> Carregando...
                    </div>

                ):
                (
                    <div className='modal-fullscreen' style={{
                        "width": "100%",
                        "position": "fixed",
                        "background": "rgba(255,255,255,.5)",
                        "top": 0,
                        "left": 0,
                        "zIndex": 999999
                    }}>
                        <Loader />
                    </div>
                )
            }
        </>
    );
}

export default Loader;