import React, { FC, useEffect, useState } from "react";
import Header from "./layout/Header";
import { Link, LinkProps, Navigate, useLocation, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import clsx from "clsx";
import { useAuth } from "../hooks/useAuth";
import { useConfig } from "../hooks/useConfig";
import Error from "./messages/Error";
import PrivateRoutes from "../routes/PrivateRoutes";
import ClipLoader from "react-spinners/ClipLoader";
import PublicRoutes from "../routes/PublicRoutes";
import { AuthContext } from "../providers/AuthContextProvider";

const LayoutInit = () => {
    useEffect(() => { }, [

    ]);
}

function CustomLink({ children, to, ...props }: LinkProps) {
    let location = useLocation();
    let match = (location.pathname.indexOf(to.toString()) > -1)

    return (
        <Link
            className={clsx("nav-link", {
                'active': match
            })}
            to={to}
            {...props}
        >
            {children}
        </Link>
    );
}

const Layout: FC = ({ children }) => {
    const { deleteToken, hasAbility } = useAuth();
    const [showTerms, setShowTerms] = useState(false);

    return (
        <>
            <AuthContext.Consumer>
                {({ termsAgreed, setTermsAgreed, user, setIsAuth }) => (
                    <>
                        <Header />
                        <div className={'d-grid canvas'} style={{ 'gridTemplateColumns': '183px 1fr', 'height': '100%' }}>
                            <div className="aside">
                                <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                                    {hasAbility("USER_LIST") && (
                                        <li><CustomLink to={'/admin/entries'}>
                                            <div className="icon-aside icon-svg">
                                                <i className="bi bi-file-earmark-text"></i>
                                            </div>Registros</CustomLink>
                                        </li>
                                    )}
                                    {hasAbility("SERVICE_APPROVE") && (
                                        <li><CustomLink to={'/admin/services'}>
                                            <div className="icon-aside icon-svg">
                                                <i className="bi bi-file-earmark-text"></i>
                                            </div>Serviços</CustomLink>
                                        </li>
                                    )}
                                    {hasAbility("BUSINESS_LIST") && (
                                        <li><CustomLink to={'/admin/businesses'}><div className="icon-aside">
                                            <i className="bi bi-building"></i>
                                        </div>Clientes</CustomLink>
                                        </li>
                                    )}
                                    {hasAbility("USER_LIST") && (
                                        <li><CustomLink to={'/admin/users'}><div className="icon-aside">
                                        <i className="bi bi-person"></i>
                                    </div>Usuários</CustomLink></li>
                                    )}
                                    {hasAbility("USER_LIST") && (
                                        <li><CustomLink to={'/admin/teams'}>
                                            <div className="icon-aside icon-svg">
                                                <i className="bi bi-person"></i>
                                            </div>Equipes</CustomLink>
                                        </li>
                                    )}
                                    {hasAbility("USER_LIST") && (
                                        <li><CustomLink to={'/admin/service-types'}>
                                            <div className="icon-aside icon-svg">
                                                <i className="bi bi-table"></i>
                                            </div>Tipos de Serviço</CustomLink>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            {children}
                        </div>
                    </>
                )}
            </AuthContext.Consumer>
        </>
    );
}
export default Layout