import React, { FC, useEffect, useReducer, useState } from "react";
import { Button, Card, Col, Container, Row, Table, Breadcrumb, Form } from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import BusinessSelect from "../../components/form/BusinessSelect";
import { useAuth } from "../../hooks/useAuth";
import moment from "moment";
import { useAPI } from "../../hooks/useAPI";
import "./styles/pairing-page.css";
import { EntryModel } from "../../models/EntryModel";
import { EntryType } from "../../types/EntryType";
import ReportFilters from "./components/ReportFilters";
import download from "downloadjs";
import Loader from "../../components/messages/Loader";

const EntryPage = () => {
    const [showConfirm, setShowConfirm] = useState(false);
    const [showConfirmPairing, setShowConfirmPairing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [searchFromBusiness, setSearchFromBusiness] = useState<number | undefined>(undefined);
    const [search, setSearch] = useState("");
    const [data, setData] = useState<Array<EntryType> | undefined>(undefined);
    const [showReport, setShowReport] = useState(false);
    const [errorPdf, setErrorPdf] = useState(false);
    const { api } = useAPI();
    const defaultOptions = {
        from: "",
        to: "",
        type: "",
        status: "MOU"
    };
    const [options, setOptions] = useState(defaultOptions);

    const { hasAbility } = useAuth();

    const loadData = (business_id: number | undefined = undefined, options: any | undefined = undefined) => {
        setLoading(true);
        EntryModel().list(business_id, options).then((data) => {
            if (typeof data !== "boolean") {
                setLoading(false);
                setData(data);
            }
        })
    }
    useEffect(() => {
        loadData(searchFromBusiness, options);
    }, []);

    const downloadReport = (reportOptions: any) => {
        setLoadingPdf(true);
        setErrorPdf(false);
        api().downloadPDF(`/api/report?${new URLSearchParams(reportOptions).toString()}`).then(response => {
            const content = response.headers['content-type'];
            download(response.data, "relatorio", content);
            setLoadingPdf(false);
        }).catch(error => {
            setLoadingPdf(false);
            setErrorPdf(true);
        });
    }

    return (
        <>
            {loadingPdf && (<Loader fullscreen={true} />)}
            <div className="bg-pattern">
                {showReport && (
                    <ReportFilters
                        onHide={() => setShowReport(false)}
                        onConfirm={(options) => {
                            setShowReport(false);
                            downloadReport(options);                            
                        }}
                    />
                )}
                <Container>
                    <Row>
                        <Breadcrumb>
                            <Breadcrumb.Item active>Registros</Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>
                    <Row>
                        <Col lg={{ offset: 0 }} md={{ span: 9 }}>
                            <Card>
                                <CardHeader className={'d-flex'} style={{ "padding": "4px 1rem" }}>
                                    <div className="card-title h5 w-100">
                                        <div className="row">
                                            <div className="col-md-6" style={{ "paddingTop": "3px" }}>
                                                REGISTROS {" "}
                                            </div>
                                        </div>
                                    </div>
                                </CardHeader>
                                <div className="card-body entries-table-container" style={{ 'padding': '0' }}>
                                    <Table striped>
                                        <thead>
                                            <tr>
                                                <th>Registro</th>
                                                <th>Situação</th>
                                                <th>Cliente</th>
                                                <th>Solicitante</th>
                                                <th>Tipo</th>
                                                <th>Descrição</th>
                                                <th>Setor/Localização</th>
                                                <th>Projeto</th>
                                                <th>Data de montagem</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading && (
                                                <tr>
                                                    <td colSpan={11} className={'text-center'}>
                                                        <ClipLoader color={'#0d6efd'} loading={loading} size={'12px'} />{" "}Carregando...
                                                    </td>
                                                </tr>
                                            )}
                                            {!loading && data?.length === 0 && (
                                                <tr>
                                                    <td colSpan={11} className={"text-center"}>
                                                        Nenhum resultado encontrado.
                                                    </td>
                                                </tr>
                                            )}
                                            {!loading && (
                                                <>
                                                    {data?.map((entry) => (
                                                        <tr key={entry.id}>
                                                            <td>
                                                                <div>{entry.id}</div>
                                                            </td>
                                                            <td>{(entry.status === "MOU") ? (<span className="text-success">MONTADO</span>) : (<span className="text-danger">DESMONTADO</span>)}</td>
                                                            <td>{entry.business?.name}</td>
                                                            <td>{entry.employee.name}</td>
                                                            <td>{entry.type}</td>
                                                            <td>
                                                                <div>{entry.description}</div>
                                                            </td>
                                                            <td>{entry.location}</td>
                                                            <td>{entry.project}</td>
                                                            <td>{moment(entry.date).format('DD/MM/YYYY')}</td>
                                                        </tr>
                                                    ))}
                                                </>
                                            )}
                                        </tbody>
                                    </Table>
                                    <Table striped>
                                        <thead>
                                            <tr>
                                                <th style={{ "width": "120px" }}>Opções <br /><br /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!loading && (
                                                <>
                                                    {data?.map((item) => (
                                                        <tr key={item.id}>
                                                            <td style={{ "textAlign": "right" }}>
                                                                <div>
                                                                    <Link to={`/admin/entries/${item.id}/view`}>
                                                                        <i className="bi bi-search icon" style={{ "color": "#25325b" }} />
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                        </Col>
                        <Col lg={{ span: 3 }}>
                            <Card>
                                <CardHeader>
                                    <Card.Title>OPÇÕES</Card.Title>
                                </CardHeader>
                                <Card.Body>
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        loadData(searchFromBusiness, options);
                                    }}>
                                        {hasAbility('BUSINESS_LIST') && (
                                            <>
                                                <label htmlFor="exampleInputEmail1" className="form-label">Cliente:</label>
                                                <BusinessSelect
                                                    key={searchFromBusiness}
                                                    businessId={searchFromBusiness}
                                                    onChange={(business_id) => {
                                                        setSearchFromBusiness(business_id);
                                                    }}
                                                />

                                                <br />
                                            </>
                                        )}
                                        <Form.Group className="mb-3">
                                            <Form.Label>Situação</Form.Label>
                                            <select
                                                className="form-select form-select-solid form-select-lg form-control"
                                                value={options.status}
                                                onChange={(e: any) => {
                                                    setOptions({ ...options, status: e.target.value });
                                                }}>
                                                <option value="">Todos</option>
                                                <option value="MOU">Montado</option>
                                                <option value="UMO">Desmontado</option>
                                            </select>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Tipo</Form.Label>
                                            <select
                                                className="form-select form-select-solid form-select-lg form-control"
                                                value={options.type}
                                                onChange={(e: any) => {
                                                    setOptions({ ...options, type: e.target.value });
                                                }}>
                                                <option value="">Todos</option>
                                                <option value="M.A.T.E">M.A.T.E</option>
                                                <option value="M.E.T.E">M.E.T.E</option>
                                                <option value="H.H">H.H</option>
                                            </select>
                                        </Form.Group>
                                        {/*<div className="row">
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>De:</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        onChange={(e) => setOptions({ ...options, from: e.target.value })}
                                                        value={options.from}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Até:</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        onChange={(e) => setOptions({ ...options, to: e.target.value })}
                                                        value={options.to}
                                                    />
                                                </Form.Group>
                                            </div>
                                            </div>*/}
                                        <div className="d-grid gap-2">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Button
                                                        variant="outline-light"
                                                        size={'lg'}
                                                        style={{ "width": "100%" }}
                                                        className="btn-custom btn-custom-light btn-custom-small text-uppercase w-100"
                                                        onClick={() => {
                                                            setOptions(defaultOptions);
                                                            setSearchFromBusiness(undefined);
                                                            loadData(undefined, defaultOptions);
                                                        }}
                                                    >Limpar</Button>
                                                </div>
                                                <div className="col-md-6">
                                                    <Button variant="primary" size={'lg'}
                                                        type="submit" className="btn-custom btn-custom-small bg-custom-gradient w-100">Buscar</Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <hr />
                                                <Button
                                                    variant="primary"
                                                    size={'lg'}
                                                    type="button"
                                                    className="btn-custom btn-custom-small bg-custom-gradient w-100"
                                                    onClick={() => setShowReport(true)}
                                                >
                                                    <i className="bi bi-file-earmark-spreadsheet icon" /> Relatório</Button>
                                            </div>
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default EntryPage;