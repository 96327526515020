import React, { useState } from "react";
import TermsModal from "../../components/TermsModal";
import { useNavigate } from "react-router-dom";

const Politica = () => {
    const [showTerms, setShowTerms] = useState(true);
    const navigate = useNavigate();

    return (
        <>
            <TermsModal
                show={showTerms}
                readOnly={true}
                onConfirm={() => {
                    navigate('/auth/login');
                }}
                onHide={() => {
                    navigate('/auth/login');
                }}
            />
        </>
    );
}

export default Politica;