import {useAPI} from "../hooks/useAPI";
import {TeamType} from "../types/BusinessType";

export function TeamModel() {
    const {api} = useAPI();

    const create = async (team: TeamType) => {
        return new Promise<boolean | TeamType>((resolve) => {
            api()
                .post("/api/team", team)
                .then((resp) => {
                    resolve(resp.data.team);
                })
                .catch(() => resolve(false));
        });
    }

    const update = async (team_id: number, team: TeamType) => {
        return new Promise<boolean | TeamType>((resolve) => {
            api()
                .put(`/api/team/${team_id}`, team)
                .then((resp) => {
                    resolve(resp.data.team);
                })
                .catch(() => resolve(false));
        });
    }

    const get = async (team_id: number | string) => {
        return new Promise<TeamType | boolean>((resolve) => {
            api()
                .get(`/api/team/${team_id}`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }

    const list = async (search: string | undefined = "") => {
        return new Promise<boolean | Array<TeamType>>((resolve) => {
            api()
                .get(`/api/team?search=${search}`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }    

    const remove = async (team_id: number) => {
        return new Promise<boolean>((resolve) => {
            api()
                .del(`/api/team/${team_id}`)
                .then((resp) => {
                    resolve(true);
                })
                .catch(() => resolve(false));
        });
    }

    return {
        create,
        update,
        remove,
        list,
        get
    };
 }