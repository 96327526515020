import React from "react";
import {Route, Routes} from "react-router-dom";
import Login from "../modules/auth/Login";
import Politica from "../modules/auth/Politica";

const PublicRoutes = () => {
    return (
        <Routes>
            <Route path={'/auth/login'} element={<Login />} />
            <Route path={'/auth/politica'} element={<Politica />} />
        </Routes>
    );
}

export default PublicRoutes;