import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row, Table, Breadcrumb, Form, Modal } from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/messages/Loader";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import { OptionType } from "../../types/CommonType";
import CustomSelect from "../../components/form/CustomSelect";
import InputMask from "react-input-mask";
import clsx from "clsx";
import moment from "moment";
import { EntryModel } from "../../models/EntryModel";
import { EntryType } from "../../types/EntryType";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useConfig } from "../../hooks/useConfig";
import { ServiceType } from "../../types/ServiceType";

const EntryViewPage = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<EntryType>();
    const { id } = useParams();
    const [serviceToUpdate, setServiceToUpdate] = useState<undefined | ServiceType>(undefined);
    const [showImageGallery, setShowImageGallery] = useState(false);
    const { getConfig } = useConfig();

    const loadData = () => {
        setLoading(true);
        if (id !== undefined) {
            EntryModel().get(id).then((resp) => {
                if (typeof resp !== "boolean") {
                    setData(resp);
                }
                setLoading(false);
            });
        }

    }

    useEffect(() => {
        loadData();
    }, [id]);

    return (
        <>
            {loading && (<Loader fullscreen={true} />)}
            {data && (
                <div className="bg-pattern">
                    <Container style={{ 'paddingBottom': '200px' }}>
                        <Row>
                            <Breadcrumb>
                                <Breadcrumb.Item><Link to={'/admin/entries'}>Registro</Link></Breadcrumb.Item>
                                <Breadcrumb.Item active>{data.id}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                        <Row>
                            <Col lg={{ offset: 0 }}>
                                <Card>
                                    <CardHeader className={'d-flex'} style={{ "padding": "4px 1rem" }}>
                                        <div className="card-title h5 w-100">
                                            <div className="row">
                                                <div className="col-md-6" style={{ "paddingTop": "3px" }}>
                                                    DADOS DO REGISTRO {" "}
                                                </div>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <Card.Body>
                                        <Form>
                                            <Row>
                                                <Col lg={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Descrição</Form.Label>
                                                        <div className="view-data">{data.description}</div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={3}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Cliente</Form.Label>
                                                        <div className="view-data">{data.business?.name}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Solicitante</Form.Label>
                                                        <div className="view-data">{data.requester}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Tipo</Form.Label>
                                                        <div className="view-data">{data.type}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Hora Inicio</Form.Label>
                                                        <div className="view-data">{moment(data.start_time).format("HH:mm")}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Equipe</Form.Label>
                                                        <div className="view-data">{data.team.name}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Localização</Form.Label>
                                                        <div className="view-data">{data.location}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Comprimento</Form.Label>
                                                        <div className="view-data">{data.length}m</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Altura</Form.Label>
                                                        <div className="view-data">{data.height}m</div>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={3}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Setor / Responsável</Form.Label>
                                                        <div className="view-data">{data.employee.name}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Data</Form.Label>
                                                        <div className="view-data">{moment(data.date).format("DD/MM/Y")}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Hora Fim</Form.Label>
                                                        <div className="view-data">{moment(data.end_time).format("HH:mm")}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Quant. Mont. / Enc.</Form.Label>
                                                        <div className="view-data">{data.qty_mont} / {data.qty_enc}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Projeto</Form.Label>
                                                        <div className="view-data">{data.project}</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Largura</Form.Label>
                                                        <div className="view-data">{data.width}m</div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Medido</Form.Label>
                                                        <div className="view-data">{data.measured}m³</div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                                <br />

                                <Row>
                                    <Col lg={{ span: 6 }}>
                                        <Card>
                                            <CardHeader className={'d-flex'} style={{ "padding": "4px 1rem" }}>
                                                <div className="card-title h5 w-100">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{ "paddingTop": "3px" }}>
                                                            SERVIÇOS {" "}
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardHeader>
                                            <div className="card-body entries-table-container" style={{ 'padding': '0' }}>
                                                <Table striped>
                                                    <thead>
                                                        <tr>
                                                            <th>Registro</th>
                                                            <th>Aprovação</th>
                                                            <th>Serviço</th>
                                                            <th>Observação</th>
                                                            <th>Medido</th>
                                                            <th>Data</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {loading && (
                                                            <tr>
                                                                <td colSpan={11} className={'text-center'}>
                                                                    <ClipLoader color={'#0d6efd'} loading={loading} size={'12px'} />{" "}Carregando...
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {!loading && data.services !== undefined && (
                                                            <>
                                                                {data?.services.map((item) => (
                                                                    <tr key={item.id}>
                                                                        <td>{data.id}</td>
                                                                        <td>{(item.approved) ?
                                                                            (<span className="text-success">APROVADO</span>) :
                                                                            (<span className="text-warning">PENDENTE</span>)
                                                                        }</td>
                                                                        <td>
                                                                            <div>
                                                                                {(typeof item.type !== 'string') ? `${item.type.id} - ${item.type.name}` : ''}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div>{item.notes}</div>
                                                                        </td>
                                                                        <td>{item.measured}{(typeof item.type !== 'string') ? `${item.type.measurementUnit}` : ''}</td>
                                                                        <td>{moment(data.date).format("DD/MM/Y")}</td>
                                                                    </tr>
                                                                ))}
                                                            </>
                                                        )}
                                                    </tbody>
                                                </Table>
                                                <Table striped>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ "width": "120px" }}>Opções </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {loading && (
                                                            <tr>
                                                                <td><br /></td>
                                                            </tr>
                                                        )}
                                                        {!loading && data.services !== undefined && (
                                                            <>
                                                                {data?.services.map((item) => (
                                                                    <tr key={item.id}>
                                                                        <td style={{ "textAlign": "right" }}>
                                                                            <div>
                                                                                <a href="" onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    if (item.images.length === 0) {
                                                                                        alert("Nenhuma imagem cadastrada!");
                                                                                        return;
                                                                                    }
                                                                                    setServiceToUpdate(item);
                                                                                    setTimeout(() => setShowImageGallery(true), 100);
                                                                                }}>
                                                                                    <i className="bi bi-images icon" style={{ "color": "#25325b" }} />
                                                                                </a>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col lg={{ span: 6 }}>
                                        <Card>
                                            <CardHeader className={'d-flex'} style={{ "padding": "4px 1rem" }}>
                                                <div className="card-title h5 w-100">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{ "paddingTop": "3px" }}>
                                                            PISOS {" "}
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardHeader>
                                            <div className="card-body" style={{ 'padding': '0' }}>
                                                <Table striped>
                                                    <thead>
                                                        <tr>
                                                            <th>Número</th>
                                                            <th>Largura</th>
                                                            <th>Comprimento</th>
                                                            <th>Medido</th>
                                                            <th>Observação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {loading && (
                                                            <tr>
                                                                <td colSpan={5} className={'text-center'}>
                                                                    <ClipLoader color={'#0d6efd'} loading={loading} size={'12px'} />{" "}Carregando...
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {!loading && data.additives !== undefined && (
                                                            <>
                                                                {data?.additives.filter((additive) => additive.type === 'TYPE_FLOOR').map((item, index) => (
                                                                    <tr key={item.id}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{item.width}</td>
                                                                        <td>{item.length}</td>
                                                                        <td>{item.measured}</td>
                                                                        <td>{item.notes}</td>
                                                                    </tr>
                                                                ))}
                                                            </>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Card>
                                        <br />
                                        <Card>
                                            <CardHeader className={'d-flex'} style={{ "padding": "4px 1rem" }}>
                                                <div className="card-title h5 w-100">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{ "paddingTop": "3px" }}>
                                                            RODAPÉS {" "}
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardHeader>
                                            <div className="card-body" style={{ 'padding': '0' }}>
                                                <Table striped>
                                                    <thead>
                                                        <tr>
                                                            <th>Número</th>
                                                            <th>Comprimento</th>
                                                            <th>Medido</th>
                                                            <th>Observação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {loading && (
                                                            <tr>
                                                                <td colSpan={4} className={'text-center'}>
                                                                    <ClipLoader color={'#0d6efd'} loading={loading} size={'12px'} />{" "}Carregando...
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {!loading && data.additives !== undefined && (
                                                            <>
                                                                {data?.additives.filter((additive) => additive.type === 'TYPE_BASEBOARD').map((item, index) => (
                                                                    <tr key={item.id}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{item.length}</td>
                                                                        <td>{item.measured}</td>
                                                                        <td>{item.notes}</td>
                                                                    </tr>
                                                                ))}
                                                            </>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={{ span: 3 }}>
                                <Card>
                                    <CardHeader>
                                        <Card.Title>OPÇÕES</Card.Title>
                                    </CardHeader>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-md-8 offset-2">
                                                <Link to="/admin/entries">
                                                    <Button variant="primary" size={'lg'} style={{ "width": "100%" }} className="btn-custom btn-custom-small text-uppercase w-100">Voltar</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )}
            {showImageGallery && serviceToUpdate !== undefined && (
                <Modal
                    size="lg"
                    show={true}
                    onHide={() => setShowImageGallery(false)}
                    aria-labelledby="example-modal-sizes-title-sm"
                    className="modal-default"
                >
                    <Modal.Header closeButton closeVariant="white">
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Imagens
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ImageGallery
                            showFullscreenButton={false}
                            showPlayButton={false}
                            // @ts-ignore
                            items={serviceToUpdate.images.map((image) => {
                                return {
                                    original: `${getConfig("API_URL")}${image.image_url}`,
                                    thumbnail: `${getConfig("API_URL")}${image.thumb_url}`
                                }
                            })} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => setShowImageGallery(false)} className="btn-custom">
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
}

export default EntryViewPage;