import React, {useContext, useState} from "react";
import './styles/auth.css';
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../providers/AuthContextProvider";
import {useAPI} from "../../hooks/useAPI";
import {useAuth} from "../../hooks/useAuth";
import ClipLoader from "react-spinners/ClipLoader";
import Logo from "./assets/logo.png";
import Error from "../../components/messages/Error";
import BGLogin from "./assets/fundo-login.jpeg";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {doLogin} = useAPI();
    const {setToken, setFeatures, setProfiles, PROFILE_BUSINESS} = useAuth();
    const {setIsAuth, setUser} = useContext(AuthContext);

    const submitHandler = (e: any) => {
        e.preventDefault();
        setLoading(true);
        doLogin(email, password)
            .then((resp) => {
                setLoading(false);
                if(resp.data.success) {
                    setToken(resp.data.token);
                    setFeatures(resp.data.features);
                    setUser(resp.data.user);
                    setProfiles(resp.data.profiles);
                    setIsAuth(true);
                    (resp.data.user.profile_id === PROFILE_BUSINESS) ? navigate('/admin/services') : navigate('/admin/entries');
                } else {
                    setShowError(true);
                    setError(resp.data.msg);
                }
            })
            .catch(() => {
                setLoading(false);
                setShowError(true);
                setError("Houve um erro desconhecido, tente novamente.");
            })
    }

    return (
        <>
            {showError && (<Error message={error} show={showError} onHide={() => setShowError(false)} />)}
            <div className={'auth-page'}>
                <main className="form-signin text-center">
                    <div className="logo-login" style={{"backgroundImage": `url(${BGLogin})`}}>
                        <img src={Logo} alt="Logo Isis"/>
                    </div>
                    <div className="form-login">
                        <form onSubmit={submitHandler}>
                            <h1 className="h3 mb-3 fw-normal">Bem-vindo à nossa <br />
                            <b>Área do Cliente!</b>
                            </h1>
                            <div className="row">
                                <div className="col-md-10 offset-2">
                                    <div className="mb-3 w-75" style={{"marginTop": "11px"}}>
                                        <input
                                            type="email"
                                            className="form-control" id="floatingInput"
                                            required
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                            placeholder="Email"
                                        />
                                    </div>
                                    <div className="mb-3 w-75" style={{"marginTop": "37px"}}>
                                        <input
                                            type="password"
                                            className="form-control"
                                            required
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password}
                                            placeholder="Senha"
                                        />
                                    </div>
                                </div>
                            </div>
                            <button
                                className="btn btn-lg btn-primary"
                                type="submit"
                                disabled={loading}
                                style={{"marginTop": "6px"}}
                            >{loading ? <ClipLoader color={'#ffffff'} loading={loading} size={'12px'} /> : 'Login'}</button>
                        </form>
                        {/*<div className="row">
                            <div className="col-md-12">
                                <Link to="#" className="create-account">Criar Conta</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Link to="#" className="forget-password">Esqueceu sua senha?</Link>
                            </div>
                        </div>*/}
                    </div>
                </main>
            </div>
        </>
    );
}

export default Login;