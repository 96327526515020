import React, { FC, useState } from "react";
import { Form, Modal, Button, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import { Employee } from "../../types/BusinessType";
import { BusinessEmployeeModel } from "../../models/BusinessEmployeeModel";
import { EmployeeModel } from "../../models/EmployeeModel";
import InputMask from "react-input-mask";
import clsx from "clsx";

interface Props {
    show: boolean
    onHide: () => void
    onSave: (employee: Employee, isUpdate: boolean) => void
    employee?: Employee
    team_id: number
}
const BusinessEmployeeAddModal: FC<Props> = ({ show, onHide, onSave, employee, team_id }) => {
    const schema = yup.object().shape({
        name: yup.string().required(),
        email: yup.string().email().required(),
        document: yup.string().required(),
    });
    const [formWasSubmitted, setFormWasSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const initialEmployee: Employee = (employee !== undefined) ? employee : {
        id: -1,
        name: "",
        email: "",
        team_id: team_id,
        document: ""
    };

    const createEmployee = async (values: Employee) => {
        return EmployeeModel().create(team_id, values);
    }

    const updateEmployee = async (employee_id: number, values: Employee) => {
        return EmployeeModel().update(team_id, employee_id, values);
    }

    const onSubmit = async (values: Employee) => {
        setLoading(true);
        setFormWasSubmitted(false);
        let createdEmployee: Employee | boolean;
        if (employee === undefined) {
            createdEmployee = await createEmployee(values);
        } else {
            createdEmployee = await updateEmployee(employee.id, values);
        }
        setLoading(false);

        // Saving result
        if (typeof createdEmployee !== "boolean") {
            onSave(createdEmployee, employee !== undefined);
        } else {
            setHasError(true);
            setErrorMsg("Houve um erro, tente novamente.");
        }
    }

    return (
        <Modal
            show={show}
            onHide={() => {
                onHide();
                setFormWasSubmitted(false);
            }}
            className="modal-default"
        >
            <Formik
                validationSchema={schema}
                onSubmit={onSubmit}
                initialValues={initialEmployee}
            >
                {({
                    setFieldValue,
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    isValid,
                    errors }) => (
                    <Form noValidate onSubmit={(e) => {
                        setFormWasSubmitted(true);
                        handleSubmit(e);
                    }}>
                        <Modal.Header closeButton closeVariant="white">
                            <Modal.Title>
                                Novo Setor / Responsável
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {(!isValid && formWasSubmitted) && (<Alert variant={'danger'}>Preencha corretamente todos os campos!</Alert>)}
                            {hasError && (<Alert variant={'danger'}>{errorMsg}</Alert>)}
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Nome"
                                    value={values.name}
                                    onChange={handleChange}
                                    isValid={touched.name && !errors.name}
                                    isInvalid={!!errors.name && formWasSubmitted}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>E-mail</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isValid={touched.email && !errors.email}
                                    isInvalid={!!errors.email && formWasSubmitted}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>CPF</Form.Label>
                                    <InputMask
                                        name={"document"}
                                        type="text"
                                        mask="999.999.999-99"
                                        value={values.document}
                                        onChange={(e) => {
                                            if (e.target.value !== null) {
                                                setFieldValue("document", e.target.value);
                                            }
                                        }}
                                        className={clsx("form-control", {
                                            "is-invalid": touched.document && errors.document,
                                            "is-valid": !errors.document && touched.document
                                        })}
                                    />
                                </Form.Group>
                            </Form.Group>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-light" onClick={onHide} className="btn-custom btn-custom-light">
                                Fechar
                            </Button>
                            <Button variant="primary" type={"submit"} disabled={loading} className="btn-custom bg-custom-gradient">
                                {loading ? <><ClipLoader color={'#ffffff'} loading={loading} size={'12px'} /> Salvando...</> : 'Salvar'}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default BusinessEmployeeAddModal;