import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row, Table, Breadcrumb, Form } from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/messages/Loader";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import { Employee, TeamType } from "../../types/BusinessType";
import { TeamModel } from "../../models/TeamModel";
import clsx from "clsx";
import InputMask from "react-input-mask";
import BusinessEmployeeAddModal from "./BusinessEmployeeAddModal";
import Confirm from "../../components/messages/Confirm";
import { BusinessEmployeeModel } from "../../models/BusinessEmployeeModel";
import { EmployeeModel } from "../../models/EmployeeModel";

const TeamEditPage = () => {
    const [showBusinessEmployeeModal, setShowBusinessEmployeeModal] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [businessEmployeeToEdit, setBusinessEmployeeToEdit] = useState<undefined | Employee>(undefined);
    const [businessEmployeeToDelete, setBusinessEmployeeToDelete] = useState<undefined | Employee>(undefined);


    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [team, setTeam] = useState<TeamType>();
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const loadTeam = () => {
        setLoading(true);
        if (id !== undefined) {
            TeamModel().get(id).then((resp) => {
                if (typeof resp !== "boolean") {
                    setTeam(resp);
                    formik.setValues(resp);
                }
                setLoading(false);
            });
        }

    }

    const schema = yup.object().shape({
        name: yup.string().required(),
        id: yup.number().nullable(),
        description: yup.string()
    });
    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
            id: -1
        },
        validationSchema: schema,
        onSubmit: (values) => {
            formik.validateForm(values).then((errors) => {
                if (Object.keys(errors).length === 0) {
                    setSaving(true);
                    if (team !== undefined) {
                        TeamModel().update(team?.id, { ...formik.values, employees: [] }).then((resp) => {
                            if (typeof resp !== "boolean") {
                                toast.success("Registro atualizado com sucesso!");
                                navigate("/admin/teams");
                            } else {
                                toast.error("Houve um erro, tente novamente!");
                                setHasSubmitted(false);
                                setSaving(false);
                            }
                        });
                    }
                }
            })
        },
        validateOnMount: false
    });

    useEffect(() => {
        loadTeam()
    }, []);

    return (
        <>
            {loading && (<Loader fullscreen={true} />)}
            {team !== undefined && (
                <div className="bg-pattern">
                    <BusinessEmployeeAddModal
                        show={showBusinessEmployeeModal}
                        employee={businessEmployeeToEdit}
                        team_id={team.id}
                        onHide={() => {
                            setBusinessEmployeeToEdit(undefined);
                            setShowBusinessEmployeeModal(false);
                        }}
                        onSave={(user, isUpdate) => {
                            toast.success(`Registro ${isUpdate ? "alterado" : "adicionado"} com sucesso!`);
                            loadTeam();
                            setBusinessEmployeeToEdit(undefined);
                            setShowBusinessEmployeeModal(false);
                        }}
                    />
                    <Confirm
                        message={'Deseja realmente excluir esse registro?'}
                        show={showConfirm}
                        onConfirm={async () => {
                            setShowConfirm(false);
                            const id = toast.loading("Removendo registro...");
                            let result = false;
                            if (businessEmployeeToDelete !== undefined) {
                                result = await EmployeeModel().remove(team.id, businessEmployeeToDelete.id);
                            } else {
                                return;
                            }

                            toast.dismiss(id);
                            if (result) {
                                toast.success("Registro excluído com sucesso!");
                                loadTeam();
                            } else {
                                toast.error(`Houve um erro ao excluir o registro: ${businessEmployeeToDelete.name}!`, {
                                    hideProgressBar: true,
                                    autoClose: false
                                });
                            }
                        }}
                        onHide={() => setShowConfirm(false)}
                    />
                    <form onSubmit={formik.handleSubmit}>
                        <Container style={{ 'paddingBottom': '200px' }}>
                            <Row>
                                <Breadcrumb>
                                    <Breadcrumb.Item><Link to={'/admin/businesses'}>Equipes</Link></Breadcrumb.Item>
                                    <Breadcrumb.Item active>{team.name}</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                            <Row>
                                <Col lg={{ offset: 0 }}>
                                    <Card className="mb-3">
                                        <CardHeader className={'d-flex'}>
                                            <Card.Title>Dados da Equipe</Card.Title>
                                        </CardHeader>
                                        <Card.Body>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h5 className="person-name">{team.name}</h5>
                                                </div>
                                            </div>
                                            <Row>
                                                <Col lg={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Nome</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="name"
                                                            placeholder="Nome"
                                                            value={formik.values.name}
                                                            onChange={formik.handleChange}
                                                            isValid={formik.touched.name && !formik.errors.name}
                                                            isInvalid={!!formik.errors.name}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={12}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Descrição</Form.Label>
                                                        <textarea name="description" rows={5} className="form-control" onChange={formik.handleChange} value={formik.values.description} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>




                                    <Card>
                                        <CardHeader className={'d-flex'} style={{ "padding": "4px 1rem" }}>
                                            <div className="card-title h5 w-100">
                                                <div className="row">
                                                    <div className="col-md-6" style={{ "paddingTop": "3px" }}>
                                                        Colaboradores
                                                    </div>
                                                    <div className="text-end col-md-6">
                                                        <a
                                                            href="#"
                                                            className="btn-plus"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setShowBusinessEmployeeModal(true)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </CardHeader>
                                        <div className="card-body" style={{ 'padding': '0' }}>
                                            <Table striped>
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Nome</th>
                                                        <th>Email</th>
                                                        <th>Documento</th>
                                                        <th>Opções</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {loading && (
                                                        <tr>
                                                            <td colSpan={5} className={'text-center'}>
                                                                <ClipLoader color={'#0d6efd'} loading={loading} size={'1rem'} />{" "}Carregando...
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {!loading && team.employees?.length === 0 && (
                                                        <tr>
                                                            <td colSpan={5} className={"text-center"}>
                                                                Nenhum resultado encontrado.
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {!loading && (
                                                        <>
                                                            {team.employees?.map((item) => (
                                                                <tr key={item.id}>
                                                                    <td>{item.id}</td>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.email}</td>
                                                                    <td>{item.document}</td>
                                                                    <td>
                                                                        <a href="#" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setBusinessEmployeeToEdit(item);
                                                                            setShowBusinessEmployeeModal(true);
                                                                        }}>
                                                                            <i className="icon icon-edit">
                                                                                <svg version="1.1" id="Camada_1"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    x="0px" y="0px"
                                                                                    viewBox="0 0 15.76 15.8">
                                                                                    <g>
                                                                                        <path className="st0" d="M14.29,6.03c-0.2,0-0.39,0.08-0.53,0.22c-0.14,0.14-0.22,0.33-0.22,0.53v6.77c0,0.2-0.08,0.39-0.22,0.53
                                                                            s-0.33,0.22-0.53,0.22H2.26c-0.2,0-0.39-0.08-0.53-0.22S1.5,13.74,1.5,13.54V3.02c0-0.2,0.08-0.39,0.22-0.53s0.33-0.22,0.53-0.22
                                                                            h6.77c0.27,0,0.52-0.14,0.65-0.38c0.13-0.23,0.13-0.52,0-0.75C9.54,0.91,9.29,0.76,9.02,0.76H2.26c-0.6,0-1.17,0.24-1.6,0.66
                                                                            C0.24,1.85,0,2.42,0,3.02v10.53c0,0.6,0.24,1.17,0.66,1.6c0.42,0.42,1,0.66,1.6,0.66h10.53c0.6,0,1.17-0.24,1.6-0.66
                                                                            c0.42-0.42,0.66-1,0.66-1.6V6.78c0-0.2-0.08-0.39-0.22-0.53C14.68,6.11,14.49,6.03,14.29,6.03z M15.42,1.67l-1.29-1.29
                                                                            c-0.28-0.28-0.68-0.42-1.08-0.37c-0.36,0.05-0.7,0.21-0.95,0.47L6.77,5.84C6.62,5.99,6.49,6.16,6.41,6.36L5.41,8.61
                                                                            C5.3,8.86,5.26,9.13,5.3,9.4c0.04,0.27,0.17,0.52,0.35,0.71c0.24,0.24,0.57,0.38,0.91,0.38c0.21,0,0.42-0.04,0.61-0.13l2.26-0.99
                                                                            c0.19-0.08,0.37-0.2,0.52-0.35l5.34-5.34c0.28-0.25,0.45-0.6,0.47-0.98C15.78,2.33,15.66,1.96,15.42,1.67L15.42,1.67z M14.24,2.65
                                                                            L8.89,7.99C8.88,8,8.86,8.02,8.84,8.03L6.95,8.85L7.81,6.9l5.34-5.34l1.14,1.08L14.24,2.65z"/>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                        </a>
                                                                        {" "}
                                                                        <a href="#" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setShowConfirm(true);
                                                                            setBusinessEmployeeToDelete(item);
                                                                        }}>
                                                                            <i className="icon icon-delete">
                                                                                <svg version="1.1" id="Camada_1"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    x="0px" y="0px"
                                                                                    viewBox="0 0 13.73 17.23">
                                                                                    <g>
                                                                                        <path className="st2" d="M11.74,3.1l-0.46,12.49c-0.01,0.21-0.2,0.4-0.41,0.4H2.87c-0.21,0-0.4-0.18-0.41-0.4L2,3.1
                                                                                    c-0.01-0.34-0.3-0.61-0.65-0.6c-0.34,0.01-0.61,0.3-0.6,0.65l0.46,12.49c0.03,0.88,0.77,1.6,1.66,1.6h7.99
                                                                                    c0.89,0,1.63-0.71,1.66-1.6l0.46-12.49C13,2.8,12.73,2.51,12.38,2.5C12.04,2.48,11.75,2.75,11.74,3.1L11.74,3.1z"/>
                                                                                        <path className="st2" d="M6.37,5.62v8.49c0,0.28,0.22,0.5,0.5,0.5s0.5-0.22,0.5-0.5V5.62c0-0.28-0.22-0.5-0.5-0.5S6.37,5.34,6.37,5.62
                                                                                    L6.37,5.62z"/>
                                                                                        <path className="st2" d="M3.62,5.63l0.25,8.49c0.01,0.28,0.24,0.49,0.51,0.48c0.28-0.01,0.49-0.24,0.48-0.51L4.62,5.6
                                                                                    C4.61,5.33,4.38,5.11,4.11,5.12C3.83,5.13,3.61,5.36,3.62,5.63L3.62,5.63z"/>
                                                                                        <path className="st2" d="M9.11,5.6l-0.25,8.49c-0.01,0.28,0.21,0.51,0.48,0.51c0.28,0.01,0.51-0.21,0.51-0.48l0.25-8.49
                                                                                    c0.01-0.28-0.21-0.51-0.48-0.51C9.35,5.11,9.12,5.33,9.11,5.6L9.11,5.6z"/>
                                                                                        <path className="st2" d="M0.62,3.37h12.49c0.34,0,0.62-0.28,0.62-0.62c0-0.34-0.28-0.62-0.62-0.62H0.62C0.28,2.12,0,2.4,0,2.75
                                                                                    C0,3.09,0.28,3.37,0.62,3.37z"/>
                                                                                        <path className="st2" d="M4.97,2.54l0.26-0.91c0.06-0.19,0.31-0.39,0.51-0.39h2.25c0.2,0,0.46,0.19,0.51,0.39l0.26,0.91l1.2-0.34
                                                                                    L9.7,1.29C9.5,0.56,8.75,0,7.99,0H5.74c-0.76,0-1.5,0.56-1.71,1.29L3.77,2.2L4.97,2.54z"/>
                                                                                    </g>
                                                                                </svg>
                                                                            </i>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={{ span: 3 }}>
                                    <Card>
                                        <CardHeader>
                                            <Card.Title>OPÇÕES</Card.Title>
                                        </CardHeader>
                                        <Card.Body>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Link to="/admin/businesses">
                                                        <Button variant="outline-light" size={'lg'} style={{ "width": "100%" }} className="btn-custom btn-custom-light btn-custom-small text-uppercase w-100">Descartar</Button>
                                                    </Link>
                                                </div>
                                                <div className="col-md-6">
                                                    <Button
                                                        className="btn-custom bg-custom-gradient btn-custom-small text-uppercase w-100"
                                                        variant="primary"
                                                        size={'lg'}
                                                        type="submit"
                                                        disabled={saving}>{saving ? <ClipLoader color={'#ffffff'} loading={saving} size={'12px'} /> : 'Salvar'}</Button>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </form>

                </div>
            )}
        </>
    );
}

export default TeamEditPage;