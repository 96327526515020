import React, {FC, useState} from "react";
import {Form, Modal, Button, Alert} from "react-bootstrap";
import {Formik} from "formik";
import * as yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import { BusinessEmployee } from "../../types/BusinessType";
import { BusinessEmployeeModel } from "../../models/BusinessEmployeeModel";

interface Props {
    show: boolean
    onHide: () => void
    onSave: (employee: BusinessEmployee, isUpdate: boolean) => void
    employee?: BusinessEmployee
    business_id: number
}
const BusinessEmployeeAddModal: FC<Props> = ({show, onHide, onSave, employee, business_id}) => {    
    const schema = yup.object().shape({
        name: yup.string().required(),
        email: yup.string().email().required(),        
    });
    const [formWasSubmitted, setFormWasSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const initialEmployee: BusinessEmployee = (employee !== undefined) ? employee : {
        id: -1,
        name: "",
        email: "",
        business_id: business_id,
    };

    const createEmployee = async (values: BusinessEmployee) => {
        return BusinessEmployeeModel().create(business_id, values);
    }

    const updateEmployee = async (employee_id: number, values: BusinessEmployee) => {
        return BusinessEmployeeModel().update(business_id, employee_id, values);
    }

    const onSubmit = async (values: BusinessEmployee) => {
        setLoading(true);
        setFormWasSubmitted(false);
        let createdEmployee: BusinessEmployee | boolean;
        if(employee === undefined) {
            createdEmployee = await createEmployee(values);
        } else {
            createdEmployee = await updateEmployee(employee.id, values);
        }
        setLoading(false);

        // Saving result
        if(typeof createdEmployee !== "boolean") {
            onSave(createdEmployee, employee !== undefined);
        } else {
            setHasError(true);
            setErrorMsg("Houve um erro, tente novamente.");
        }
    }

    return (
        <Modal
            show={show}
            onHide={() => {
                onHide();
                setFormWasSubmitted(false);
            }}
            className="modal-default"
        >
            <Formik
                validationSchema={schema}
                onSubmit={onSubmit}
                initialValues={initialEmployee}
            >
                {({
                      setFieldValue,
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      isValid,
                      errors}) => (
                    <Form noValidate onSubmit={(e) => {
                        setFormWasSubmitted(true);
                        handleSubmit(e);
                    }}>
                        <Modal.Header closeButton closeVariant="white">
                            <Modal.Title>
                                Novo Setor / Responsável
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {(!isValid && formWasSubmitted) && (<Alert variant={'danger'}>Preencha corretamente todos os campos!</Alert>)}
                            {hasError && (<Alert variant={'danger'}>{errorMsg}</Alert>)}
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Nome"
                                    value={values.name}
                                    onChange={handleChange}
                                    isValid={touched.name && !errors.name}
                                    isInvalid={!!errors.name && formWasSubmitted}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>E-mail</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isValid={touched.email && !errors.email}
                                    isInvalid={!!errors.email && formWasSubmitted}
                                />
                            </Form.Group>                                                       

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-light" onClick={onHide} className="btn-custom btn-custom-light">
                                Fechar
                            </Button>
                            <Button variant="primary" type={"submit"} disabled={loading} className="btn-custom bg-custom-gradient">
                                {loading ? <><ClipLoader color={'#ffffff'} loading={loading} size={'12px'} /> Salvando...</> : 'Salvar'}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default BusinessEmployeeAddModal;