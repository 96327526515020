import {useAPI} from "../hooks/useAPI";
import {ServiceTypeType} from "../types/ServiceTypeType";

export function ServiceTypeModel() {
    const {api} = useAPI();

    const list = async () => {
        return new Promise<boolean | Array<ServiceTypeType>>((resolve) => {
            api()
                .get(`/api/service-type`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }    

    const update = async (service_type_id: string, serviceType: ServiceTypeType) => {
        return new Promise<boolean | ServiceTypeType>((resolve) => {
            api()
                .put(`/api/service-type/${service_type_id}`, serviceType)
                .then((resp) => {
                    resolve(resp.data.serviceType);
                })
                .catch(() => resolve(false));
        });
    }

    return {
        list,
        update
    };
 }