import React, { useEffect, useReducer, useState } from "react";
import { Button, Card, Col, Container, Row, Table, Breadcrumb, Form } from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import UserAddModal from "./components/UserAddModal";
import Confirm from "../../components/messages/Confirm";
import ClipLoader from "react-spinners/ClipLoader";
import { UserListReducer } from "../../reducers/UserReducer";
import { UserModel } from "../../models/UserModel";
import { UserType } from "../../types/UserType";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import BusinessSelect from "../../components/form/BusinessSelect";
import { ServiceTypeType } from "../../types/ServiceTypeType";
import { ServiceTypeModel } from "../../models/ServiceTypeModel";
import ServiceTypeAddModal from "./components/ServiceTypeAddModal";

const ServiceTypesPage = () => {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [serviceTypeToEdit, setServiceTypeToEdit] = useState<undefined | ServiceTypeType>(undefined);
    const [data, setData] = useState<ServiceTypeType[]>([]);

    const loadServiceTypes = () => {
        setLoading(true);
        ServiceTypeModel().list().then((serviceTypeList) => {
            if (typeof serviceTypeList !== 'boolean') {
                setData(serviceTypeList);
                setLoading(false);
            }
        })
    }
    useEffect(() => {
        loadServiceTypes();
    }, []);

    return (
        <>
            {serviceTypeToEdit !== undefined && (
                <ServiceTypeAddModal
                    show={showModal}
                    serviceType={serviceTypeToEdit}
                    onHide={() => {
                        setServiceTypeToEdit(undefined);
                        setShowModal(false);
                    }}
                    onSave={(serviceType, isUpdate) => {
                        toast.success(`Serviço ${isUpdate ? "alterado" : "adicionado"} com sucesso!`);
                        loadServiceTypes();
                        setServiceTypeToEdit(undefined);
                        setShowModal(false);
                    }}
                />
            )}
            <div className="bg-pattern">
                <Container>
                    <Row>
                        <Breadcrumb>
                            <Breadcrumb.Item active>Tipos de Serviço</Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>
                    <Row>
                        <Col lg={{ offset: 0 }}>
                            <Card>
                                <CardHeader>
                                    <Card.Title>Tipos de serviço</Card.Title>
                                </CardHeader>
                                <div className="card-body" style={{ 'padding': '0' }}>
                                    <Table striped>
                                        <thead>
                                            <tr>
                                                <th>Serviço</th>
                                                <th>Unidade</th>
                                                <th>Preço</th>
                                                <th>Opções</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading && (
                                                <tr>
                                                    <td colSpan={4} className={'text-center'}>
                                                        <ClipLoader color={'#0d6efd'} loading={loading} size={'1rem'} />{" "}Carregando...
                                                    </td>
                                                </tr>
                                            )}
                                            {!loading && data.length === 0 && (
                                                <tr>
                                                    <td colSpan={4} className={"text-center"}>
                                                        Nenhum resultado encontrado.
                                                    </td>
                                                </tr>
                                            )}
                                            {!loading && (
                                                <>
                                                    {data.map((item) => (
                                                        <tr key={item.id}>
                                                            <td>{item.id} - {item.name}</td>
                                                            <td>{item.measurementUnit}</td>
                                                            <td>R${item.price.toString().replace(".", ",")}</td>
                                                            <td>
                                                                <a href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowModal(true);
                                                                    setServiceTypeToEdit(item);
                                                                }}>
                                                                    <i className="icon icon-edit">
                                                                        <svg version="1.1" id="Camada_1"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            x="0px" y="0px"
                                                                            viewBox="0 0 15.76 15.8">
                                                                            <g>
                                                                                <path className="st0" d="M14.29,6.03c-0.2,0-0.39,0.08-0.53,0.22c-0.14,0.14-0.22,0.33-0.22,0.53v6.77c0,0.2-0.08,0.39-0.22,0.53
                                                                            s-0.33,0.22-0.53,0.22H2.26c-0.2,0-0.39-0.08-0.53-0.22S1.5,13.74,1.5,13.54V3.02c0-0.2,0.08-0.39,0.22-0.53s0.33-0.22,0.53-0.22
                                                                            h6.77c0.27,0,0.52-0.14,0.65-0.38c0.13-0.23,0.13-0.52,0-0.75C9.54,0.91,9.29,0.76,9.02,0.76H2.26c-0.6,0-1.17,0.24-1.6,0.66
                                                                            C0.24,1.85,0,2.42,0,3.02v10.53c0,0.6,0.24,1.17,0.66,1.6c0.42,0.42,1,0.66,1.6,0.66h10.53c0.6,0,1.17-0.24,1.6-0.66
                                                                            c0.42-0.42,0.66-1,0.66-1.6V6.78c0-0.2-0.08-0.39-0.22-0.53C14.68,6.11,14.49,6.03,14.29,6.03z M15.42,1.67l-1.29-1.29
                                                                            c-0.28-0.28-0.68-0.42-1.08-0.37c-0.36,0.05-0.7,0.21-0.95,0.47L6.77,5.84C6.62,5.99,6.49,6.16,6.41,6.36L5.41,8.61
                                                                            C5.3,8.86,5.26,9.13,5.3,9.4c0.04,0.27,0.17,0.52,0.35,0.71c0.24,0.24,0.57,0.38,0.91,0.38c0.21,0,0.42-0.04,0.61-0.13l2.26-0.99
                                                                            c0.19-0.08,0.37-0.2,0.52-0.35l5.34-5.34c0.28-0.25,0.45-0.6,0.47-0.98C15.78,2.33,15.66,1.96,15.42,1.67L15.42,1.67z M14.24,2.65
                                                                            L8.89,7.99C8.88,8,8.86,8.02,8.84,8.03L6.95,8.85L7.81,6.9l5.34-5.34l1.14,1.08L14.24,2.65z"/>
                                                                            </g>
                                                                        </svg>
                                                                    </i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                        </Col>
                        <Col lg={{ span: 3 }}>
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                loadServiceTypes();
                            }}>
                                <Card>
                                    <CardHeader>
                                        <Card.Title>OPÇÕES</Card.Title>
                                    </CardHeader>
                                    <Card.Body>
                                        <div className="d-grid gap-2">
                                            <div className="row">
                                                <div className="col-md-8 offset-2">
                                                    <Button variant="primary" size={'lg'} type="submit" className="btn-custom btn-custom-small bg-custom-gradient w-100">Recarregar</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ServiceTypesPage;