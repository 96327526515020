import {UserType} from "../types/UserType";
import {useAPI} from "../hooks/useAPI";
import { Employee } from "../types/BusinessType";

export function EmployeeModel() {
    const {api} = useAPI();

    const create = async (team_id: number, businessEmployee: Employee) => {
        return new Promise<boolean | Employee>((resolve) => {
            api()
                .post(`/api/team/${team_id}/employee`, businessEmployee)
                .then((resp) => {
                    resolve(resp.data.employee);
                })
                .catch(() => resolve(false));
        });
    }

    const update = async (team_id: number, employee_id: number, employee: Employee) => {
        return new Promise<boolean | Employee>((resolve) => {
            api()
                .put(`/api/team/${team_id}/employee/${employee_id}`, employee)
                .then((resp) => {
                    resolve(resp.data.employee);
                })
                .catch(() => resolve(false));
        });
    }
    const remove = async (team_id: number, employee_id: number) => {
        return new Promise<boolean>((resolve) => {
            api()
                .del(`/api/team/${team_id}/employee/${employee_id}`)
                .then((resp) => {
                    resolve(true);
                })
                .catch(() => resolve(false));
        });
    }

    return {
        create,
        update,
        remove
    };
 }