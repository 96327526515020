import {UserType} from "../types/UserType";
import {useAPI} from "../hooks/useAPI";
import { BusinessEmployee } from "../types/BusinessType";

export function BusinessEmployeeModel() {
    const {api} = useAPI();

    const create = async (business_id: number, businessEmployee: BusinessEmployee) => {
        return new Promise<boolean | BusinessEmployee>((resolve) => {
            api()
                .post(`/api/business/${business_id}/employee`, businessEmployee)
                .then((resp) => {
                    resolve(resp.data.employee);
                })
                .catch(() => resolve(false));
        });
    }

    const update = async (business_id: number, employee_id: number, employee: BusinessEmployee) => {
        return new Promise<boolean | BusinessEmployee>((resolve) => {
            api()
                .put(`/api/business/${business_id}/employee/${employee_id}`, employee)
                .then((resp) => {
                    resolve(resp.data.employee);
                })
                .catch(() => resolve(false));
        });
    }
    const remove = async (business_id: number, employee_id: number) => {
        return new Promise<boolean>((resolve) => {
            api()
                .del(`/api/business/${business_id}/employee/${employee_id}`)
                .then((resp) => {
                    resolve(true);
                })
                .catch(() => resolve(false));
        });
    }

    return {
        create,
        update,
        remove
    };
 }