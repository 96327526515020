import React, {useState} from 'react';
import {BrowserRouter, Navigate, useLocation, useNavigate} from "react-router-dom";
import PublicRoutes from "./PublicRoutes";
import Layout from "../components/Layout";
import PrivateRoutes from "./PrivateRoutes";
import {AuthContext} from "../providers/AuthContextProvider";
import ClipLoader from "react-spinners/ClipLoader";
import {useAuth} from "../hooks/useAuth";

const AuthWrapper = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {deleteToken} = useAuth();
    const [showSubscriptionError, setShowSubscriptionError] = useState(true);
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

    const logout = (setIsAuth: any) => {
        deleteToken();
        setIsAuth(false);
        navigate("/auth/login");
    };

    return (
        <AuthContext.Consumer>
            {({isAuth, loading, termsAgreed, setTermsAgreed, user, setIsAuth}) => (
                <>
                    {isAuth ?
                        <>
                            {!loading && (
                                <Layout>
                                    <PrivateRoutes />
                                </Layout>
                            )}
                            {loading && (
                                <>
                                    <div style={{
                                        "fontSize": "1rem",
                                        "position": "fixed",
                                        "top": "45%",
                                        "width": "100%"
                                    }} className={"text-center"}>
                                        <ClipLoader size={"1rem"} /> Carregando...
                                    </div>
                                </>
                            )}
                        </>
                        :
                        <>
                            {!['/auth/login','/auth/politica'].includes(location.pathname) && (<Navigate to={'/auth/login'} />)}
                        </>
                    }

                    {!isAuth ?
                        <PublicRoutes /> : null
                    }
                </>
            )}
        </AuthContext.Consumer>
    );
}

const RoutesWrapper = () => {
    return (
        <BrowserRouter>
            <AuthWrapper />
        </BrowserRouter>
    )
}

export default RoutesWrapper;