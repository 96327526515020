import {useAPI} from "../hooks/useAPI";
import { EntryType } from "../types/EntryType";

export function EntryModel() {
    const {api} = useAPI();

    const list = async (business_id: number | undefined = undefined, search: string | undefined = "") => {
        return new Promise<boolean | Array<EntryType>>((resolve) => {
            api()
                .get(`/api/entry?business_id=${(business_id !== undefined) ? business_id : ""}&${new URLSearchParams(search).toString()}`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }    

    const create = async (data: EntryType) => {
        return new Promise<boolean | EntryType>((resolve) => {
            api()
                .post("/api/entry", data)
                .then((resp) => {
                    resolve(resp.data.entry);
                })
                .catch(() => resolve(false));
        });
    }

    const update = async (id: number, data: EntryType) => {
        return new Promise<boolean | EntryType>((resolve) => {
            api()
                .put(`/api/entry/${id}`, data)
                .then((resp) => {
                    resolve(resp.data.entry);
                })
                .catch(() => resolve(false));
        });
    }

    const get = async (id: number | string) => {
        return new Promise<EntryType | boolean>((resolve) => {
            api()
                .get(`/api/entry/${id}`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }

    return {
        list,
        create,
        update,
        get
    };
 }