import React, {FC} from "react";
import {Button, Modal} from "react-bootstrap";

interface Props {
    message: string
    show: boolean
    onConfirm: () => void
    onHide: () => void
}
const Confirm: FC<Props> = ({message, show, onConfirm, onHide}) => {
    return (
        <Modal
            size="sm"
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-sm"
            className="modal-default"
        >
            <Modal.Header closeButton closeVariant="white">
                <Modal.Title id="example-modal-sizes-title-sm">
                    Confirmação
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>{message}</h6>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-light" onClick={onHide} className="btn-custom btn-custom-light">
                    Cancelar
                </Button>
                <Button variant="primary" onClick={onConfirm} className="btn-custom bg-custom-gradient">
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default Confirm;