import React, { FC, useContext, useEffect, useReducer, useState } from "react";
import { Button, Card, Col, Container, Row, Table, Breadcrumb, Form, Modal } from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import { Link, useNavigate } from "react-router-dom";
import Confirm from "../../components/messages/Confirm";
import ClipLoader from "react-spinners/ClipLoader";
import { UserType } from "../../types/UserType";
import BusinessSelect from "../../components/form/BusinessSelect";
import { useAuth } from "../../hooks/useAuth";
import { UserListReducer } from "../../reducers/UserReducer";
import { UserModel } from "../../models/UserModel";
import { toast } from "react-toastify";
import moment from "moment";
import download from "downloadjs";
import { useAPI } from "../../hooks/useAPI";
import Loader from "../../components/messages/Loader";
import "./styles/pairing-page.css";
import Error from "../../components/messages/Error";
import { EntryModel } from "../../models/EntryModel";
import { EntryType } from "../../types/EntryType";
import { type } from "os";
import { ServiceType } from "../../types/ServiceType";
import { ServiceModel } from "../../models/ServiceModel";
import BusinessEmployeeSelect from "../../components/form/BusinessEmployeeSelect";
import { AuthContext } from "../../providers/AuthContextProvider";
// @ts-ignore
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useConfig } from "../../hooks/useConfig";

interface OptionsProp {
    approved: string | undefined
    employee_id: string | number | undefined
}

const ServicesPage = () => {
    const [showConfirmApprove, setShowConfirmApprove] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<Array<EntryType> | undefined>(undefined);
    const { user } = useContext(AuthContext);
    const [serviceToUpdate, setServiceToUpdate] = useState<undefined | ServiceType>(undefined);
    const [showImageGallery, setShowImageGallery] = useState(false);
    const { getConfig } = useConfig();

    const { api } = useAPI();
    const defaultOptions: OptionsProp = {
        approved: "0",
        employee_id: ""
    };
    const [options, setOptions] = useState(defaultOptions);

    const { hasAbility } = useAuth();

    const loadData = (options: any) => {
        setLoading(true);
        EntryModel().list(undefined, options).then((data) => {
            if (typeof data !== "boolean") {
                setLoading(false);
                setData(data);
            }
        })
    }
    useEffect(() => {
        loadData(options);
    }, []);

    const approveService = (id: number) => {
        setLoading(true);
        api().put(`/api/service/${id}/approve`, {}).then(resp => {
            toast.success(`Serviço aprovado com sucesso!`);
            setLoading(false);
            loadData(options);
        }).catch(error => {
            setLoading(false);
        });
    }

    return (
        <>
            <Confirm
                message={'Deseja realmente aprovar esse serviço?'}
                show={showConfirmApprove}
                onConfirm={() => {
                    if (serviceToUpdate?.id !== undefined) {
                        setShowConfirmApprove(false);
                        approveService(serviceToUpdate?.id);
                    }
                }}
                onHide={() => {
                    setShowConfirmApprove(false);
                }}
            />
            <div className="bg-pattern">
                <Container>
                    <Row>
                        <Breadcrumb>
                            <Breadcrumb.Item active>Serviços</Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>
                    <Row>
                        <Col lg={{ offset: 0 }} md={{ span: 9 }}>
                            <Card>
                                <CardHeader className={'d-flex'} style={{ "padding": "4px 1rem" }}>
                                    <div className="card-title h5 w-100">
                                        <div className="row">
                                            <div className="col-md-6" style={{ "paddingTop": "3px" }}>
                                                SERVIÇOS {" "}
                                            </div>
                                        </div>
                                    </div>
                                </CardHeader>
                                <div className="card-body entries-table-container" style={{ 'padding': '0' }}>
                                    <Table striped>
                                        <thead>
                                            <tr>
                                                <th>Registro</th>
                                                <th>Aprovação</th>
                                                <th>Descrição</th>
                                                <th>Serviço</th>
                                                <th>Setor/Localização</th>
                                                <th>Solicitante</th>
                                                <th>Projeto</th>
                                                <th>Medido</th>
                                                <th>Data de montagem</th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading && (
                                                <tr>
                                                    <td colSpan={11} className={'text-center'}>
                                                        <ClipLoader color={'#0d6efd'} loading={loading} size={'12px'} />{" "}Carregando...
                                                    </td>
                                                </tr>
                                            )}
                                            {!loading && (
                                                <>
                                                    {data?.map((entry) => {
                                                        return (entry.services === undefined) ? "" : entry.services.filter((service) =>
                                                            (typeof service.type !== "string") ? service.type.requireApproval : true
                                                        ).filter((service) => {
                                                            if (options.approved !== "") {
                                                                return service.approved.toString() === options.approved;
                                                            }
                                                            return true;
                                                        }).map((item) => (
                                                            <tr key={item.id}>
                                                                <td>
                                                                    <div>{entry.id}</div>
                                                                </td>
                                                                <td>{(item.approved) ?
                                                                    (<span className="text-success">APROVADO</span>) :
                                                                    (<span className="text-warning">PENDENTE</span>)
                                                                }</td>
                                                                <td>
                                                                    <div>
                                                                    {entry.description}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                    {(typeof item.type !== 'string') ? `${item.type.id} - ${item.type.name}` : ''}
                                                                    </div>
                                                                </td>
                                                                <td>{entry.location}</td>
                                                                <td>{entry.employee.name}</td>
                                                                <td>{entry.project}</td>
                                                                <td>{item.measured}{(typeof item.type !== 'string') ? `${item.type.measurementUnit}` : ''}</td>
                                                                <td>{moment(entry.date).format('DD/MM/YYYY')}</td>                                                                
                                                            </tr>
                                                        ));
                                                    })}
                                                </>
                                            )}
                                        </tbody>
                                    </Table>
                                    <Table striped>
                                        <thead>
                                            <tr>
                                                <th style={{ "width": "120px" }}>Opções <br /><br /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading && (
                                                <tr>
                                                    <td><br /></td>
                                                </tr>
                                            )}
                                            {!loading && (
                                                <>
                                                    {data?.map((entry) => {
                                                        return (entry.services === undefined) ? "" : entry.services.filter((service) =>
                                                            (typeof service.type !== "string") ? service.type.requireApproval : true
                                                        ).filter((service) => {
                                                            if (options.approved !== "") {
                                                                return service.approved.toString() === options.approved;
                                                            }
                                                            return true;
                                                        })
                                                            .map((item) => (
                                                                <tr key={item.id}>
                                                                    <td style={{ "textAlign": "right" }}>
                                                                        <div>
                                                                            {!item.approved && (
                                                                                <a href="#" onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    setServiceToUpdate(item);
                                                                                    setShowConfirmApprove(true);
                                                                                }}>
                                                                                    <i className="bi bi-check-square icon" style={{ "color": "#25325b" }} />
                                                                                </a>
                                                                            )}
                                                                            <a href="" onClick={(e) => {
                                                                                e.preventDefault();
                                                                                if(item.images.length === 0) {
                                                                                    alert("Nenhuma imagem cadastrada!");
                                                                                    return;
                                                                                }

                                                                                setServiceToUpdate(item);
                                                                                setTimeout(() => setShowImageGallery(true), 100);
                                                                            }}>
                                                                                <i className="bi bi-images icon" style={{ "color": "#25325b" }} />
                                                                            </a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ));
                                                    })}
                                                </>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                        </Col>
                        <Col lg={{ span: 3 }}>
                            <Card>
                                <CardHeader>
                                    <Card.Title>OPÇÕES</Card.Title>
                                </CardHeader>
                                <Card.Body>
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        loadData(options);
                                    }}>
                                        {user?.business_id !== undefined && (
                                            <>
                                                <label htmlFor="exampleInputEmail1" className="form-label">Solicitante:</label>
                                                <BusinessEmployeeSelect
                                                    key={options.employee_id}
                                                    businessId={user?.business_id}
                                                    employeeId={options.employee_id}
                                                    onChange={(employee_id) => {
                                                        setOptions({ ...options, employee_id: (employee_id !== undefined) ? employee_id : "" });
                                                        loadData({ ...options, employee_id: (employee_id !== undefined) ? employee_id : "" });
                                                    }}
                                                />
                                                <br />
                                            </>
                                        )}
                                        <Form.Group className="mb-3">
                                            <Form.Label>Aprovação</Form.Label>
                                            <select
                                                className="form-select form-select-solid form-select-lg form-control"
                                                value={options.approved}
                                                onChange={(e: any) => {
                                                    setOptions({ ...options, approved: e.target.value });
                                                    loadData({ ...options, approved: e.target.value });
                                                }}>
                                                <option value="">Todos</option>
                                                <option value="1">Aprovado</option>
                                                <option value="0">Pendente</option>
                                            </select>
                                        </Form.Group>
                                        <div className="d-grid gap-2">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Button
                                                        variant="outline-light"
                                                        size={'lg'}
                                                        style={{ "width": "100%" }}
                                                        className="btn-custom btn-custom-light btn-custom-small text-uppercase w-100"
                                                        onClick={() => {
                                                            setOptions(defaultOptions);
                                                            loadData(defaultOptions);
                                                        }}
                                                    >Limpar</Button>
                                                </div>
                                                <div className="col-md-6">
                                                    <Button variant="primary" size={'lg'}
                                                        type="submit" className="btn-custom btn-custom-small bg-custom-gradient w-100">Buscar</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {showImageGallery && serviceToUpdate !== undefined && (
                <Modal
                    size="lg"
                    show={true}
                    onHide={() => setShowImageGallery(false)}
                    aria-labelledby="example-modal-sizes-title-sm"
                    className="modal-default"
                >
                    <Modal.Header closeButton closeVariant="white">
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Imagens
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ImageGallery
                            showFullscreenButton={false}
                            showPlayButton={false}
                            // @ts-ignore
                            items={serviceToUpdate.images.map((image) => {
                                return {
                                    original: `${getConfig("API_URL")}${image.image_url}`,
                                    thumbnail: `${getConfig("API_URL")}${image.thumb_url}`
                                }
                            })} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => setShowImageGallery(false)} className="btn-custom">
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
}

export default ServicesPage;