import React, {FC, useState} from "react";
import {Form, Modal, Button, Alert} from "react-bootstrap";
import ProfilesSelect from "./ProfilesSelect";
import {Formik} from "formik";
import * as yup from "yup";
import {UserModel} from "../../../models/UserModel";
import {UserType} from "../../../types/UserType";
import ClipLoader from "react-spinners/ClipLoader";
import {useAuth} from "../../../hooks/useAuth";
import {AuthContext} from "../../../providers/AuthContextProvider";

interface Props {
    show: boolean
    onHide: () => void
    onSave: (user: UserType, isUpdate: boolean) => void
    user?: UserType
}
const UserAddModal: FC<Props> = ({show, onHide, onSave, user}) => {
    const {PROFILE_MASTER, PROFILE_BUSINESS} = useAuth();
    const schema = yup.object().shape({
        name: yup.string().required(),
        email: yup.string().email().required(),
        profile_id: yup.number().required().positive(),
        password: (user === undefined) ? yup.string().required() : yup.string(),
    });
    const [formWasSubmitted, setFormWasSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const initialUser: UserType = (user !== undefined) ? user : {
        id: -1,
        name: "",
        email: "",
        profile_id: -1,
        password:""
    };

    const createUser = async (values: UserType) => {
        return UserModel().create(values);
    }

    const updateUser = async (user_id: number, values: UserType) => {
        return UserModel().update(user_id, values);
    }

    const onSubmit = async (values: UserType) => {
        setLoading(true);
        setFormWasSubmitted(false);
        let createdUser: UserType | boolean;
        if(user === undefined) {
            createdUser = await createUser(values);
        } else {
            createdUser = await updateUser(user.id, values);
        }
        setLoading(false);

        // Saving result
        if(typeof createdUser !== "boolean" && createdUser !== null) {
            onSave(createdUser, user !== undefined);
        } else {
            setHasError(true);
            setErrorMsg(createdUser === null ? "Você atingiu o limite de cadastros do seu plano." : "Houve um erro, tente novamente.");
        }
    }

    return (
        <Modal
            show={show}
            onHide={() => {
                onHide();
                setFormWasSubmitted(false);
            }}
            className="modal-default"
        >
            <Formik
                validationSchema={schema}
                onSubmit={onSubmit}
                initialValues={initialUser}
            >
                {({
                      setFieldValue,
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      isValid,
                      errors}) => (
                    <Form noValidate onSubmit={(e) => {
                        setFormWasSubmitted(true);
                        handleSubmit(e);
                    }}>
                        <Modal.Header closeButton closeVariant="white">
                            <Modal.Title>
                                Novo Usuário
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {(!isValid && formWasSubmitted) && (<Alert variant={'danger'}>Preencha corretamente todos os campos!</Alert>)}
                            {hasError && (<Alert variant={'danger'}>{errorMsg}</Alert>)}
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Nome"
                                    value={values.name}
                                    onChange={handleChange}
                                    isValid={touched.name && !errors.name}
                                    isInvalid={!!errors.name && formWasSubmitted}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>E-mail</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isValid={touched.email && !errors.email}
                                    isInvalid={!!errors.email && formWasSubmitted}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="profile_id">
                                <Form.Label>Função</Form.Label>
                                <AuthContext.Consumer>
                                    {({user}) => (
                                        <ProfilesSelect
                                            onChange={(selectedOption) => {
                                                setFieldValue('profile_id', selectedOption.value);
                                            }}
                                            name={"profile_id"}
                                            value={values.profile_id}
                                            isValid={touched.profile_id && !errors.profile_id}
                                            isTouched={touched.profile_id}
                                            allowed={(user?.profile_id === PROFILE_MASTER) ? [PROFILE_MASTER, PROFILE_BUSINESS] : [PROFILE_BUSINESS]}
                                            disabledOptions={(user?.profile_id === PROFILE_MASTER) ? [PROFILE_BUSINESS] : []}
                                            disabled={initialUser.id > -1}
                                        />
                                    )}
                                </AuthContext.Consumer>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="password">
                                <Form.Label>Senha {user !== undefined && (<small>(Deixe em branco para manter a senha atual.)</small>)}</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    placeholder="Senha"
                                    value={values.password}
                                    onChange={handleChange}
                                    isValid={touched.password && !errors.password}
                                    isInvalid={!!errors.password && formWasSubmitted}
                                />
                            </Form.Group>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-light" onClick={onHide} className="btn-custom btn-custom-light">
                                Fechar
                            </Button>
                            <Button variant="primary" type={"submit"} disabled={loading} className="btn-custom bg-custom-gradient">
                                {loading ? <><ClipLoader color={'#ffffff'} loading={loading} size={'12px'} /> Salvando...</> : 'Salvar'}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default UserAddModal;