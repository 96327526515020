import React, {FC, useState} from "react";
import {OptionType} from "../../types/CommonType";
import {BusinessModel} from "../../models/BusinessModel";
import {BusinessEmployee} from "../../types/BusinessType";
import Async from "react-select/async";
import clsx from "clsx";

interface Props {
    onChange: (business_id: number | undefined) => void
    isValid?: boolean
    isTouched?: boolean
    businessId: string | number
    employeeId?: number | string
}
const BusinessEmployeeSelect: FC<Props> = ({onChange, isTouched=undefined, isValid=undefined, businessId, employeeId=undefined}) => {
    const [defaultValue, setDefaultValue] = useState<OptionType | null>(null);

    const loadBusiness = async (inputValue: string) => {
        return new Promise<Array<BusinessEmployee>>((resolve) => {
            BusinessModel().listEmployees(businessId).then((businessList) => {
                if(typeof businessList !== "boolean") {
                    resolve(businessList);
                    updateDefaultValue(businessList);
                } else {
                    resolve([]);
                }
            });
        });
    }

    const updateDefaultValue = (businessList: Array<BusinessEmployee>) => {
        let item: BusinessEmployee;
        let i: any;
        for(i in businessList) {
            item = businessList[i];
            if(item.id.toString() === employeeId?.toString()) {
                setDefaultValue({label: item.name, value: item.id});
            }
        }
    }

    return (
        <Async
            value={defaultValue}
            className={clsx("react-select-container", {
                "is-invalid": isTouched && !isValid,
                "is-valid": isTouched && isValid
            })}
            classNamePrefix={'react-select'}
            placeholder={"Selecione..."}
            isSearchable={false}
            defaultOptions={true}
            isClearable={true}
            onChange={(selectedOption: OptionType | null) => {
                setDefaultValue(selectedOption);
                onChange((selectedOption !== null) ? selectedOption.value : undefined);
            }}
            loadOptions={(inputValue, callback) => {
                loadBusiness(inputValue).then((businessList) => {
                    callback(businessList.map((item: BusinessEmployee) => {
                        return {label: item.name, value: item.id};
                    }));
                });
            }}
        />
    );
}

export default BusinessEmployeeSelect;