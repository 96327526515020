import LogRocket from "logrocket";
import React from "react";

class ErrorBoundary extends React.Component {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo:any) {
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, errorInfo);
        try {
            LogRocket.error(error, errorInfo);
        } catch(e) {}
    }

    render() {
        // @ts-ignore
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div style={{'padding': '100px'}}>
                    <h3>Algo deu errado! <a href="">Recarregar Página</a></h3>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;