import React, {FC, useEffect, useState} from 'react'
import Select from 'react-select'
import clsx from "clsx";

interface Props {
    options: Array<any>
    onChange: any
    name: string
    value?: any
    placeholder?: string
    isSearchable?:boolean
    isValid?: boolean
    isTouched?: boolean
    disabled?: boolean
    isMulti?: boolean
    isClearable?:boolean
}
const CustomSelect: FC<Props> = (
    {
        options,
        onChange,
        value=undefined,
        placeholder="Selecione...",
        isSearchable=false,
        isValid=false,
        isTouched=false,
        disabled=false,
        isMulti=false,
        isClearable=true
    }) => {



    const getSelectedOption = (options: Array<any>, value: any) => {
        if(value === undefined || value === null) return undefined;
        for(let i in options) {
            if(options[i].value.toString() === value.toString()) {
                return options[i];
            }
        }

        return undefined;
    }

    const getSelectedOptions = (options: Array<any>, value: any) => {
        const selected = [];
        if(value !== null) {
            const values = value.split(",");
            for(let i in values) {
                selected.push(getSelectedOption(options, values[i]));
            }
        }

        return selected;
    }

    const [defaultValue, setDefaultValue] = useState(
        (!isMulti) ? getSelectedOption(options, value) : getSelectedOptions(options, value)
    );

    return (
        <>
            <Select
                isDisabled={disabled}
                options={options}
                onChange={onChange}
                defaultValue={defaultValue}
                placeholder={placeholder}
                isSearchable={isSearchable}
                className={clsx("react-select-container", {
                    "is-invalid": isTouched && !isValid,
                    "is-valid": isTouched && isValid
                })}
                classNamePrefix={'react-select'}
                isMulti={isMulti}
                isClearable={isClearable}
            />
        </>
    )
}

export default CustomSelect;