import React, {useEffect, useState} from "react";
import {Button, Card, Col, Container, Row, Table, Breadcrumb, Form} from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import {Link, useNavigate, useParams} from "react-router-dom";
import Loader from "../../components/messages/Loader";
import ClipLoader from "react-spinners/ClipLoader";
import {toast} from "react-toastify";
import * as yup from "yup";
import {useFormik} from "formik";
import {TeamType} from "../../types/BusinessType";
import {TeamModel} from "../../models/TeamModel";
import clsx from "clsx";
import InputMask from "react-input-mask";

const TeamAddPage = () => {
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const navigate = useNavigate();

    const schema = yup.object().shape({
        name: yup.string().required(),
        description: yup.string(),
        id: yup.number().nullable(),        
    });
    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
            id: -1,
            employees: []
        },
        validationSchema: schema,
        onSubmit: (values) => {
            formik.validateForm(values).then((errors) => {
                if(Object.keys(errors).length === 0) {
                    setSaving(true);
                    TeamModel().create(values).then((resp) => {
                        if(typeof resp !== "boolean") {
                            toast.success("Equipe cadastrada com sucesso!");
                            navigate("/admin/teams");
                        } else {
                            toast.error("Houve um erro, tente novamente!");
                            setHasSubmitted(false);
                            setSaving(false);
                        }
                    });
                }
            })
        },
        validateOnMount: false
    });

    return (
        <>
            {loading && (<Loader fullscreen={true} />)}
            <div className="bg-pattern">
                <form onSubmit={formik.handleSubmit}>
                <Container style={{'paddingBottom': '200px'}}>
                    <Row>
                        <Breadcrumb>
                            <Breadcrumb.Item><Link to={'/admin/businesses'}>Equipes</Link></Breadcrumb.Item>
                            <Breadcrumb.Item active>Nova Equipe</Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>
                    <Row>
                        <Col lg={{offset: 0}}>
                            <Card>
                                <CardHeader className={'d-flex'}>
                                    <Card.Title>Dados da Equipe</Card.Title>
                                </CardHeader>
                                <Card.Body>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Nome</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    placeholder="Nome"
                                                    value={formik.values.name}
                                                    onChange={formik.handleChange}
                                                    isValid={formik.touched.name && !formik.errors.name}
                                                    isInvalid={!!formik.errors.name}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Descrição</Form.Label>
                                                <textarea name="description" rows={5} className="form-control" onChange={formik.handleChange} value={formik.values.description}/>                                                
                                            </Form.Group>
                                        </Col>                                                                               
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={{span: 3}}>
                            <Card>
                                <CardHeader>
                                    <Card.Title>OPÇÕES</Card.Title>
                                </CardHeader>
                                <Card.Body>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Link to="/admin/teams">
                                                <Button variant="outline-light" size={'lg'} style={{"width": "100%"}} className="btn-custom btn-custom-light btn-custom-small text-uppercase w-100">Descartar</Button>
                                            </Link>
                                        </div>
                                        <div className="col-md-6">
                                            <Button type="submit" className="btn-custom bg-custom-gradient btn-custom-small text-uppercase w-100" variant="primary" size={'lg'} disabled={saving}>{saving ? <ClipLoader color={'#ffffff'} loading={saving} size={'12px'} /> : 'Salvar'}</Button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                </form>
            </div>
        </>
    );
}

export default TeamAddPage;