import React, { FC, useState } from "react";
import { Form, Modal, Button, Alert } from "react-bootstrap";
import ProfilesSelect from "./ProfilesSelect";
import { Formik } from "formik";
import * as yup from "yup";
import { UserModel } from "../../../models/UserModel";
import { UserType } from "../../../types/UserType";
import ClipLoader from "react-spinners/ClipLoader";
import { useAuth } from "../../../hooks/useAuth";
import { AuthContext } from "../../../providers/AuthContextProvider";
import { ServiceTypeType } from "../../../types/ServiceTypeType";
import { ServiceTypeModel } from "../../../models/ServiceTypeModel";

interface Props {
    show: boolean
    onHide: () => void
    onSave: (serviceType: ServiceTypeType, isUpdate: boolean) => void
    serviceType: ServiceTypeType
}
const ServiceTypeAddModal: FC<Props> = ({ show, onHide, onSave, serviceType }) => {
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [formWasSubmitted, setFormWasSubmitted] = useState(false);
    const [price, setPrice] = useState(serviceType.price);

    const onSubmit = async (serviceType: ServiceTypeType) => {
        setLoading(true);
        const resp = await ServiceTypeModel().update(serviceType.id, serviceType);
        setLoading(false);

        // Saving result
        if (typeof resp !== "boolean") {
            onSave(serviceType, true);
        } else {
            setHasError(true);
            setErrorMsg("Houve um erro, tente novamente.");
        }
    }

    return (
        <Modal
            show={show}
            onHide={() => {
                onHide();
                setFormWasSubmitted(false);
            }}
            className="modal-default"
        >
            <Form noValidate onSubmit={(e) => {
                setFormWasSubmitted(true);
                e.preventDefault();
                setHasError(false);

                if(price === "") {
                    setErrorMsg("O preço não pode ficar em branco!");
                    setHasError(true);
                    return;
                }

                onSubmit({...serviceType, price: price});
            }}>
                <Modal.Header closeButton closeVariant="white">
                    <Modal.Title>
                        Novo Usuário
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {hasError && (<Alert variant={'danger'}>{errorMsg}</Alert>)}
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Serviço</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder="Serviço"
                            disabled={true}
                            value={`${serviceType.id} - ${serviceType.name}`}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label>Preço (R$)</Form.Label>
                        <Form.Control
                            type="number"
                            name="price"
                            placeholder="Preço"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}                            
                        />
                    </Form.Group>                   

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-light" onClick={onHide} className="btn-custom btn-custom-light">
                        Fechar
                    </Button>
                    <Button variant="primary" type={"submit"} disabled={loading} className="btn-custom bg-custom-gradient">
                        {loading ? <><ClipLoader color={'#ffffff'} loading={loading} size={'12px'} /> Salvando...</> : 'Salvar'}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default ServiceTypeAddModal;