import React from 'react';
import RoutesWrapper from "./routes/RoutesWrapper";
import {AuthContext, AuthContextProvider} from "./providers/AuthContextProvider";
import './App.css';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from "react-spinners/ClipLoader";

function App() {
    return (
        <AuthContextProvider>
            <RoutesWrapper/>
            <AuthContext.Consumer>
                {({loading, isAuth}) => (
                    <>
                        {!loading && (
                            <>
                                <ToastContainer
                                    position="bottom-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                    theme="colored"
                                />
                            </>
                        )}
                        {loading && (
                            <>
                                <div style={{
                                    "fontSize": "1rem",
                                    "position": "fixed",
                                    "top": "45%",
                                    "width": "100%"
                                }} className={"text-center"}>
                                    <ClipLoader size={"1rem"} /> Carregando...
                                </div>
                            </>
                        )}
                    </>
                )}
            </AuthContext.Consumer>
        </AuthContextProvider>
    );
}

export default App;
