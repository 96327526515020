export function useConfig() {
    // const ENV = "DEV";
    const ENV = "PROD";
    const configs: any = {
        // 'API_URL' : "http://localhost",
        // 'SITE_URL' : "http://localhost:3000",


        'API_URL' : "https://api.iscaff.com.br",
        'SITE_URL' : "https://iscaff.com.br",
    };

    const getConfig = (key: string) => {
        for(let i in configs) {
            if(i === key){
                return configs[i];
            }
        }

        return false
    }

    return {
        getConfig
    };
}
